/**
 * Dropdowns
 */

import $ from 'jquery';

export default {
	init() {
		// really only consumed when a dropdown is ajax
		$('.dropdown__list').append(
			'<li class="dropdown__item dropdown__loader"><span><div class="dropdown__thumb"><div class="loading --block white"></div></div>Loading...</span></div>'
		);

		$(document).on('mouseenter.dropdown', '.dropdown__item:first-of-type', function () {
			$(this).parents('.dropdown__list').addClass('--first-active');
		});

		$(document).on('mouseleave.dropdown', '.dropdown__item:first-of-type', function () {
			$(this).parents('.dropdown__list').removeClass('--first-active');
		});

		$(document).on('mousedown.dropdown', '.dropdown__item:first-of-type', function () {
			$(this).parents('.dropdown__list').addClass('--navigating');
		});

		$(document).on('focusin.dropdown', '.dropdown__item', function () {
			$(this).parents('.dropdown').addClass('--open');
		});

		$(document).on('focusin.dropdown', '.dropdown__item:first-of-type', function () {
			$(this).parents('.dropdown__list').addClass('--first-active');
		});

		$(document).on('focusout.dropdown', '.dropdown__item:first-of-type', function () {
			$(this).parents('.dropdown__list').removeClass('--first-active');
		});

		$(document).on('focusout.dropdown', '.dropdown__item', function () {
			$(this).parents('.dropdown').removeClass('--open');
		});

		$('.dropdown[class*="--nav"]').on('click', function () {
			if ($(document).innerWidth() < 798) {
				$(this).toggleClass('--expand');
			}
		});

		$(document).ajaxStart(() => {
			$(document).find('.dropdown__list').addClass('--working');
		});

		$(document).ajaxComplete(() => {
			$(document).find('.dropdown__item:first-of-type').parents('.dropdown__list').removeClass('--navigating');
			$(document).find('.dropdown__list').removeClass('--working');
		});

		// This function is a duplicate of `close()` below.
		// Due to scoping issues and the way `this` works, I couldn't figure out how to re-use the function.
		$(document).on('click', '.dropdown__item', function () {
			const dropDown = $(this).parents('.dropdown')[0];

			if (!dropDown) return;

			if (dropDown.classList.contains('mobile-navigation')) {
				return;
			}

			// Hack to get dropdown to close when clicked without page refresh.
			dropDown.replaceWith(dropDown);

			// If first nav item clicked, reset the --navigating after DOM replacement ಠ_ಠ
			const dropDownList = dropDown.querySelector('.dropdown__list');
			if (dropDownList) {
				dropDownList.classList.remove('--navigating');
			}
		});
	},

	close(idOrElement) {
		const dropDown = this.ensureElement(idOrElement);

		// Hack to get dropdown to close when clicked without page refresh.
		dropDown.replaceWith(dropDown);

		// If first nav item clicked, reset the --navigating after DOM replacement ಠ_ಠ
		const dropDownList = dropDown.querySelector('.dropdown__list');
		if (dropDownList) {
			dropDownList.classList.remove('--navigating');
		}
	},

	ensureElement(idOrElem) {
		if (typeof idOrElem === 'string' || idOrElem instanceof String) {
			return document.getElementById(idOrElem);
		}
		return idOrElem;
	}
};
